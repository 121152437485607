import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { EntityResolver } from "../../edtell-portal/abstracts/entity.resolver";
import { RouterDataInfo } from "../../edtell-portal/interfaces/router-data-info";
import { UserService } from "../services/user.service";

@Injectable({
    providedIn: "root"
})
export class UserResolver extends EntityResolver {

    private previousUser: any

    constructor(private userService: UserService) { super() }

    async resolve(route: ActivatedRouteSnapshot) {

        let data = route.data
        let id = route.paramMap.get("id")

        if (data.routerData.paramId != null) {
            id = data[data.routerData.paramId]
        }

        if (this.previousUser == null || this.previousUser.id != id) {
            this.previousUser = await this.userService.getUser(id).toPromise();
        }

        return this.previousUser
    }

    async process(route: ActivatedRouteSnapshot, routerData: RouterDataInfo) {
        await this.resolve(route)
        routerData.title = this.previousUser.lastName
    }

}
